.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin: 16px;
  cursor: pointer;
  min-width: 300px;
}

.image-container {
  position: relative;
  /* height: 139px; */
  overflow: hidden;
}

.title-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, #000000, #d9d9d900);
  color: white;
  padding: 8px;
  text-align: left;
  font-size: 16px;
  z-index: 1;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.image {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.details-container {
  padding: 16px;
}

.mt-2 {
  margin-top: 10px;
}

.space-between {
  justify-content: space-between;
}

.d-flex-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.label {
  flex: 0 0 auto;
  white-space: nowrap;
}

.value {
  flex: 1;
  min-width: 0;
}

.location {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: right;
}

.p-2 {
  padding: 10px;
}
