.flex-center {
  justify-content: center;
  display: flex;
}

.without-margin {
  margin: 0 auto;
}

.render-data-wrap {
  background-color: white;
  min-width: 350px;
  border-radius: 8px;
}

.unit-wrap {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #f3feff;
}

.small-left {
  margin-left: 10px;
}

.regular-padding {
  padding: 20px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.regular-margin-top {
  margin-top: 20px;
}

.text-center {
  text-align: center;
}

.map-wrap {
  height: 70vh;
  background-color: white;
}

.display-flex {
  display: flex;
}

.custom-flex {
  flex: 0.1;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-container-map {
  height: 70vh;
  flex: 1;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}

.chevron-right {
  flex: 0.1;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.full-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}
