.farmer-card {
  background-color: #ffffff;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .farmer-card {
    width: calc(30%);
  }
}

@media (max-width: 1005px) {
  .farmer-card {
    width: calc(100% - 10px);
    /* max-width: 345px; */
  }
}

.card-wrap {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.icon-container {
  padding: 4px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.icon-container img {
  width: 32px;
  height: 32px;
}

.icon-wrap {
  background-color: #ecfaff;
  padding: 20px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr-2 {
  margin-right: 10px;
}

.detail-farmer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.detail-farmer__content {
  width: 100%;
  max-width: 720px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detail-farmer__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.detail-farmer__description {
  font-size: 16px;
  color: #666;
}

.regular-padding-horizontal {
  padding-right: 16px;
  padding-left: 16px;
}

.regular-padding-vertical {
  padding-top: 16px;
  padding-bottom: 16px;
}

.small-border-radius {
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.back-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-wrap {
  flex: 1;
  width: 100%;
  max-width: 760px;
}

.cursor-pointer {
  cursor: pointer;
}

.image-list-container-farmer {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
  gap: 10px;
}

/* .image-list-container-farmer::-webkit-scrollbar {
  display: none;
} */

.image-item-farmer {
  min-width: 127px;
  /* height: auto; */
  max-width: 130px;
}

.image-farmer {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  height: 100px;
  cursor: pointer;
}

.farmer-inventory {
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.inventory-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.inventory-container {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.inventory-items-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 5px;
}

.inventory-card {
  min-width: 150px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  margin-right: 10px;
  border: 1px solid #d1d1d1;
}

.inventory-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.inventory-label {
  font-weight: bold;
  color: #333;
  min-width: 100px;
  text-align: left;
  flex: 1;
}

.inventory-value {
  flex-grow: 1;
  color: #555;
  text-align: right;
}
